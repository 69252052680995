import React from 'react'
import InputText from '../components/inputText'
import TextArea from '../components/textArea'
import Button from '../components/button'
import { useState } from 'react'
import { useRef } from 'react'
import axios from 'axios'
import '../styles/inputs.css'

const Formpqrs = () => {


    // creamos los states para capturar los mensajes
  const [nombre, setNombre] = useState('');
  const [correo, setCorreo] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [tipo ,setTipo] = useState('');
  const [telefono, setTelefono] = useState('');
  const [info, setInfo] = useState('Enviar')

  //Referenciar los imputs para despues dejarlos vacios
  const refNombre = useRef(null);
  const refCorreo = useRef(null);
  const refTelefono = useRef(null);
  const refMensaje = useRef(null);
  const refEmpresa = useRef(null);

  //Objeto tipo de solicitud
  var solicitudes = ['Peticion', 'Queja', 'Reclamo',"Sugerencia", "Elogio"];

  //configuracion JSON
  const config = {
    headers: {
        "Content-Type": "application/json",
    },
    };

  //const selSolicitud = (elemento) => {
   // setSoli(elemento);
  //}

  //Funcion para borrar los inputs luego de enviar el mensaje de manera exitosa
  const borrarIputs = () => {
    setNombre('');
    setCorreo('');
    setTelefono('');
    setMensaje('');
    setTipo('');
    refNombre.current.value = '';
    refCorreo.current.value = '';
    refTelefono.current.value = '';
    refMensaje.current.value = '';
    refEmpresa.current.value = '';
  } 


  //Funcion para llenar tipos de solicitudes
  const llenarSolicitudes = () => {
    return solicitudes.map((element) => {
      return <option value={element} className='option-drop'>{element}</option>
    });
  }

  //Funcion para enviar el mensaje
  const enviarMensaje = async() => { 

    //Variables para capturar los valores en cada uno de los states
    const m = mensaje;
    const t = telefono;
    const c = correo;
    const n = nombre;
    const s = tipo;

    //Validamos si alguna de las variables esta vacia, lo que quiere decir que algun campo no fue completado
    if(m === '' || t === '' || c === '' || n === '' || s === ''){
      //En caso tal enviamos mensaje 
      setInfo('Ningun campo puede estar vacio')
      setTimeout(() => {
        setInfo('Enviar')
      }, 3000);
    }else{//Si no

      //Construction body
      const body = JSON.stringify({
        "nombre" : nombre,
        "correo" : correo,
        "telefono" : telefono, 
        "mensaje" : mensaje,
        "empresa" : empresa,
        "tipo" : tipo
        })
      
      //Instanciacion para enviar la informacion mediante la clase Axios, lo que retorna el servidor queda guardado en answer
      const answer = await axios.post(`http://localhost:5000/createPQRS`,body,config);
      
      //Evaluamos la respuesta del servidor
      if(answer.data === true){ //Si la respuesta del servidor es verdadera
        
        //Enviamos mensaje 
        setInfo('Gestionado!');
        borrarIputs();
        setTimeout(() => {
          setInfo('Enviar'); 
        }, 3000);
        
      }else //Si no
      {
        //We send the message, erase the inputs and set the info
        setInfo('EL mensaje no se pudo enviar!');
        borrarIputs();
        setTimeout(() => {
          setInfo('Enviar')
        }, 3000);
      }
    }
  }

  //Funcion para capturar el nombre escrito
  const escribirNombre = (e) => {
    var name = e.target.value;
    setNombre(name);
    
  }

  //Funcion para capturar el nombre de la empresa ingresado
  const escribirEmpresa = (e) => {
    var empresa = e.target.value;
    setEmpresa(empresa);
    
  }

  //Funcion para capturar el correo escrito
  const escribirCorreo = (e) => {
    var corr = e.target.value;
    setCorreo(corr);
    
  }

  //Funcion para capturar el telefono escrito
  const escribirTelefono = (e) => {
    var tel = e.target.value;
    setTelefono(tel)
  }

  const escribirMensaje = (e) => {
    var mensaje = e.target.value;
    setMensaje(mensaje);
  } 
  return (
    <div className='form-pqr'>
              <InputText tipo={'text'} escribir={escribirNombre} place={'Nombre completo'} clase='input' referencia={refNombre}/>
              <InputText tipo={'text'} escribir={escribirEmpresa} place={'Nombre empresa'} clase='input' referencia={refEmpresa}/>
              <select className="input" name='Seleccione tipo contrato' multiple={false} onChange={e =>setTipo(e.target.value)}>
                {llenarSolicitudes()}
              </select>
              <InputText tipo={'email'} escribir={escribirCorreo} place={'Correo'} clase='input' referencia={refCorreo}/>
              <InputText tipo={'tel'} escribir={escribirTelefono} place={'Teléfono/Celular'} clase='input' referencia={refTelefono}/>
              <TextArea place={'Mensaje'} clase={'textarea'} escribir={escribirMensaje} referencia={refMensaje}/>
              <button onClick={enviarMensaje} className='button button-secondary button-pipaluk'>{info}</button>
              
    </div>
  )
}

export default Formpqrs