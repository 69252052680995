import React from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import emailjs from 'emailjs-com'
import '../styles/inputs.css'

const Formcontact = ({clase}) => {

    //Declaration states chanps to send message

  const [mensaje, setMensaje] = useState('');
  const [info, setInfo] = useState('Enviar');
  const [borrar, setBorrar] = useState(false);
  const form = useRef();


  //Referenciar los imputs para despues dejarlos vacios
  const refNombre = useRef(null);
  const refCorreo = useRef(null);
  const refTelefono = useRef(null);
  const refMensaje = useRef(null);

  const borrarIputs = () => {
    refNombre.current.value = '';
    refCorreo.current.value = '';
    refTelefono.current.value = '';
    refMensaje.current.value = '';
  } 

  //Funcion para enviar el mensaje
  const enviarMensaje = (e) => { 

    e.preventDefault();
      emailjs.sendForm('service_prydojf', 'template_9bcd21r', form.current, 'm-crBm0fPtUbhmwNK')
      .then((result) => {
        console.log(result);
        //Enviamos mensaje 
        setInfo('Mensaje enviado');
        borrarIputs();
        setTimeout(() => {
          setInfo('Enviar')
          setBorrar(true);
        }, 3000);
      }, (error) => {
        console.error(error.text);
        //Enviamos mensaje 
        setInfo('EL mensaje no se pudo enviar!');
        borrarIputs();
        setTimeout(() => {
          setInfo('Enviar')
        }, 3000);
      });
      
    }
  return (
    <form className={clase} onSubmit={enviarMensaje} ref={form}>
       <input type="text" name="name" placeholder='Nombre completo' className='input' ref={refNombre} required='true'/>
       <input type="email" name="email" placeholder='Email' className='input' ref={refCorreo} required='true'/>
       <input type="tel" name="telephone" placeholder='Teléfono/Celular' className='input' ref={refTelefono} required='true'/>
       <textarea name="message" id="message" cols="30" rows="10" className='textarea' ref={refMensaje} required='true'></textarea>
       <button type="submit" className='button button-secondary button-pipaluk' placeholder='Mensaje'>{info}</button>
    </form>
  )
}

export default Formcontact