import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../bootstrap.css'
import '../style.css'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import '../styles/tabs.css'

const Services = () => {

      const navegando = useNavigate();

      const INavigate = (seleccionada) => {
        navegando(seleccionada);
      }
    
  return (
    
      
<div className='GridContainer' data-aos="fade-left">
<section class="section section-sm" id='Services'>
<div class="tabServices">
  
    <div class="columna">
        <div className='tete-columna'>
            <h5 class="box-icon-classic-title">Desarrollo a la Medida</h5>
            <p class="box-icon-classic-text">¡Impulsa la transformación digital de tu empresa con nuestros servicios especializados! Ofrecemos implementación de herramientas tecnológicas corporativas y desarrollo de aplicaciones web, móviles y de escritorio multiplataforma. </p>
        </div>
        <div className='botonera-columna'>
            <h5 class="button button-secondary button-pipaluk" onClick={() => INavigate('/Desarrollo')}>Saber más</h5>
        </div>
        
    </div>
    <div class="columna">
        <div className='tete-columna'>
            <h5 class="box-icon-classic-title">Asesoria Jurídica</h5>
            <p class="box-icon-classic-text"> ¡Aquí encuentras el respaldo legal que necesitas! En nuestra firma, ofrecemos mucho más que simples servicios jurídicos. Nos especializamos en Consultoría, Asesoría y Ejecución Jurídica y de Litigio tanto para empresas como para personas naturales, brindando soluciones efectivas y personalizadas para tus necesidades legales.</p>
        </div>
        <div className='botonera-columna'>
            <h5 class="button button-secondary button-pipaluk" onClick={() => INavigate('/Juridica')}>Saber más</h5>
        </div>
    </div>
    <div class="columna">
        <div className='tete-columna'>
            <h5 class="box-icon-classic-title">Sistema Integrado de Gestión</h5>
            <p class="box-icon-classic-text"> ¡Confía en nosotros para llevar tu negocio al siguiente nivel! Nos especializamos en Consultoría, Estructuración, Implementación, Seguimiento y Control de Sistemas de Gestión Integral de Calidad y Sistemas de Seguridad y Salud en el Trabajo. Ya sea a nivel nacional o internacional, estamos aquí para fortalecer y optimizar tus procesos empresariales.</p>
        </div>
        <div className='botonera-columna'>
            <h5 class="button button-secondary button-pipaluk" onClick={() => INavigate('/SIG')}>Saber más</h5>
        </div>
    </div>
    <div class="columna">
        <div className='tete-columna'>
            <h5 class="box-icon-classic-title">Servicios de Inmigración y Migración</h5>
            <p class="box-icon-classic-text">¡Contáctanos hoy y comienza tu viaje hacia un futuro brillante en el extranjero! Ofrecemos un servicio de asesoría integral para la solicitud de estatus migratorio en Canadá y Estados Unidos, cubriendo todas tus necesidades.</p>
        </div>
        <div className='botonera-columna'>
            <h5 class="button button-secondary button-pipaluk" onClick={() => INavigate('/Migracion')}>Saber más</h5>
        </div>
    </div>
</div>
</section>

</div>
    

    

    
    
  )
}

export default Services
