import React from 'react'
import Pie from '../components/footer'
import FormContacto from '../components/formcontact'
import { useEffect } from 'react'
import Agroandes from '../images/imagesCl/Agroandes.png'
import Certi from '../images/imagesCl/Certicontrol.png'
import GasControl from '../images/imagesCl/Gascontrol.png'
import Fruits from '../images/imagesCl/Fruits.png'
import MCI from '../images/imagesCl/MCI.png'
import Rapid from '../images/imagesCl/Rapidgas.png'
import SPI from '../images/imagesCl/SPI.png'
import ServicesGrid from '../components/services'
import Tete from '../components/tetePage'
import Whatsapp from '../components/whatsapp'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import '../bootstrap.css'
import '../style.css'
import '../styles/bannerClients.css'
import '../styles/tabs.css'
import { Helmet } from 'react-helmet';
import { useState } from 'react'
import { useRef } from 'react'
import '../styles/buttons.css'
import ReactPlayer from 'react-player'

const Index = () => {

  useEffect(() => {
    AOS.init({duration: 1000});
    
  }, []);

  const styleBanner = {
    background: 'white',
    color: 'blue',
    fontSize: '16px',
    padding: '10px',
    width: '100%',
    opacity: '0.9',
  };

  return (

    <div>
      <Helmet>
                <title>Geserprosas - Home</title>
                <meta name="description" content="Bienvenido a la pagina de inicio de Geserpro S.A.S. Aqui encontraras todos los servicios relacionados con el desarrollo web, servicios juridicos, sistemas integrados de gestion, servicios de asesoria para inmigracion y obtencion de visa americana y canadiense" />
                <meta name="keywords" content="sistema integrado de gestion, desarrollo web,desarrollo mobil, desarrollo aplicaciones, aplicaciones a medida, servicios juridicos, asesoria, calidad, ISO, auditoria, contabilidad, empresas, Canada, Estados Unidos, Visa, migracion, inmigracion" />
                <meta name="author" content="Gestion y servicios productivos S.A.S." />
                <link rel="canonical" href="https://www.geserprosas.com/" />
      </Helmet>

      <Tete/>
    
    <div class="content">
    {/*<!-- A que nos dedicamos-->*/}
    
    

        <section class="section section-sm section-first bg-default text-md-left">
        <div class="container">
          {/* Contenedor de los botones */}
      
          <div class="row row-50 justify-content-center justify-content-xl-between">
          <div class="col-lg-6 text-center wow fadeInUp"><ReactPlayer
                url={"https://www.youtube.com/watch?v=94RfW4QTEEw"}
                width='100%'
                height='400px'
                position= 'relative' 
                controls
                fallback={true}
                playing
            />
            </div>

            <div class="col-lg-6 wow fadeInRight" data-wow-delay=".1s">
              <div class="box-width-lg-470">
                <h3>NUESTROS SERVICIOS</h3>
                {/*<!-- Bootstrap tabs-->*/}
                <div class="tabs-custom tabs-horizontal tabs-line tabs-line-big tabs-line-style-2 text-center text-md-left" id="tabs-7">
                {/*<!-- Tab panes-->*/}
                  <div class="tab-content">
                    <div class="tab-pane fade show active">
                      <p>Somos una compañía con un gran campo de acción en cuanto a servicios refiere: Sistema Integrado de gestión para empresas, asesoría jurídica para personas naturales o jurídicas, desarrollo a medida; aplicaciones web, desktop o móvil. </p>
                      <div class="group-md group-middle"><a class="button button-secondary button-pipaluk" href="#Services">Ir a servicios</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    
    

        <div className='banner' style={styleBanner} data-aos="fade-down">
            <h4 className='titulo-seccion-clients'>QUIENES CONFÍAN EN NOSOTROS</h4>
            <div className='clients-line'>
              <img src={MCI} alt="logo-client" className='logo-client'/>
              <img src={Agroandes} alt="logo-client" className='logo-client'/>
              <img src={Fruits} alt="logo-client" className='logo-client'/>
              <img src={Certi} alt="logo-client" className='logo-client'/>
              <img src={GasControl} alt="logo-client" className='logo-client'/>
              <img src={Rapid} alt="logo-client" className='logo-client'/>
              <img src={SPI} alt="logo-client" className='logo-client'/>
              {/*<img src={Cylinders} alt="logo-client" className='logo-client'/>*/}
            </div>
        </div>
        
       
        <ServicesGrid />
        


        <div className='linea-division'>
            <hr className='linea-horizontal-index'/>
            <h3 className='title-service'>CONTACTO</h3>
            <hr className='linea-horizontal-index'/>
        </div>


        <div class="section section-sm">
        <div className='contacto' id='contacto' data-aos="fade-right">
          <FormContacto clase={"formulario-contacto"}/>
          <div className='esp-mapa'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.7903370912704!2d-74.07499372424994!3d4.631459942252337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9a2bf4627c5b%3A0xeddd5df86fda7b10!2sDg.%2042a%20%2319-17%2C%20Bogot%C3%A1%2C%20Colombie!5e0!3m2!1sfr!2sca!4v1683571829254!5m2!1sfr!2sca" 
                  width="100%"  
                  height="90%" 
                  style={{border: "0", borderRadius:"0px"}} 
                  allowfullscreen="true" 
                  loading="lazy" 
                  referrerpolicy="no-referrer-when-downgrade">  
          </iframe>
          </div>

          
        </div>
        </div>
        
        <Whatsapp/>
        
    </div>
    
    <Pie/>
    </div>
    
  )
}

export default Index