import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Contact from './pages/contactus.jsx'
import Nosotros from './pages/nosotros.jsx'
import Pqrs from './pages/pqr.jsx'
import Desarrollo from './pages/serviceti.jsx'
import Sig from './pages/servicequality.jsx'
import Juridica from './pages/services.jsx'
import Index from './pages/index.jsx'
import Migration from './pages/migration.jsx'



const App = () => {

  
  return (
    <div>
      <BrowserRouter>
        <Routes className='container'>
          <Route exact path='/' element={<Index />}/>
          <Route exact path='/Migracion' element={<Migration />}/>
          <Route exact path='/Contact' element={<Contact />}/>
          <Route exact path='/Juridica' element={<Juridica />}/>
          <Route exact path='/Desarrollo' element={<Desarrollo />}/>
          <Route exact path='/SIG' element={<Sig />}/>
          <Route exact path='/Nosotros' element={<Nosotros />}/>
          <Route exact path='/PQR' element={<Pqrs />}/>
        </Routes>
      </BrowserRouter>
    </div> 
  )
}

export default App