import React from 'react'
import Pie from '../components/footer'
import FormPQRS from '../components/formpqrs'
import Tete from '../components/tetePage'
import Whatsapp from '../components/whatsapp'

const Pqr = () => {

  return (
    
      <div className='page'>
        <Tete/>
    
   
        <div className='contenedor-pqr'>
        <div className='caja-contenido-pqr'>
            <p className='p-caja-cont-pqr'>
            <h3>Apreciado Usuario:</h3>  

            Valoramos enormemente su opinión y su experiencia con nuestros servicios. Por lo que nos gustaría invitarlo a compartir sus comentarios, solicitudes, quejas, reclamos y sugerencias a través de este espacio dedicado para tales fines. Su participación nos ayudará a mejorar y cumplir con los estándares establecidos.
            <br/>
            <br/>
            ¡Gracias por contribuir a nuestro continuo progreso y excelencia en el servicio! 
            Atentamente,
            <br/>
            <br/>
            Gestión y Servicios Productivos S.A.S
            <br/>
            
            (Constitución Política de Colombia y Ley 1755 de 2015)
            </p>
            <FormPQRS/>
        </div>
        </div>
        <Whatsapp/>
        

   

    <Pie/>
</div>
        
  )
}

export default Pqr