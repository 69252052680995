import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Avatart from '../icons/Avatargeser.png'

/**
 * The `App` function is a JavaScript function that exports a default component.
 * This component is used to render a Floating WhatsApp button on a web page.
 * 
 * The `FloatingWhatsApp` component takes several props:
 * 
 * - `phoneNumber` (string): The phone number to which the WhatsApp messages will
 * be sent.
 * - `accountName` (string): The name of the WhatsApp account.
 * - `chatMessage` (string): The initial message to be displayed in the WhatsApp
 * chat.
 * - `statusMessage` (string): The status message to be displayed in the WhatsApp
 * chat.
 * - `avatar` (string): The URL of the avatar image to be displayed in the WhatsApp
 * chat.
 * - `placeholder` (string): The placeholder text to be displayed in the WhatsApp
 * chat input field.
 * - `notificationSound` (boolean): Determines whether a notification sound should
 * be played when a new message is received.
 * - `notificationDelay` (number): The delay in seconds before the notification
 * sound is played.
 * 
 * The `App` function returns the `FloatingWhatsApp` component with the specified
 * props. This component can be used to render the Floating WhatsApp button on a
 * web page.
 */
export default function App() {

  return (
    <FloatingWhatsApp phoneNumber='573118816116' accountName='Geserprosas' chatMessage='Bienvenido, como podemos ayudarle?' statusMessage='Responde en 10 min aproximandamente' avatar={Avatart} placeholder='Escriba un mensaje aqui...' notificationSound={true} notificationDelay={10}/>
  )
}