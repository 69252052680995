import React from 'react'
import logo from '../logos/LogoGeserProSAS.png';
import Logo from '../components/icono';
import ElementoBarraPage from './elementoBarraPage';
import { useNavigate } from 'react-router-dom';

const TetePage = () => {

    const navigate = useNavigate();

    const Inicio = () => {
        navigate('/');
    }
    const Juridica = () => {
        navigate('/Juridica');
    }

    const Desarrollo = () => {
      navigate('/Desarrollo');
    }

    const Sig = () => {
      navigate('/SIG');
    }

    const Pqr = () => {
      navigate('/PQR');
    }

    const Nosotros = () => {
      navigate('/Nosotros');
    }

    const Migration = () => {
      navigate('/Migracion');
    }

  return (
    <div className='tete'>
        <Logo recurso={logo} texto={'Logo Geserpro SAS'} clase={'imagenLogodash'}/>
        <ElementoBarraPage name={'INICIO'} onClick={Inicio} clase={'elemento-tete-page'}/>
        <ElementoBarraPage name={'VISAS'} onClick={Migration} clase={'elemento-tete-page'}/>
        <ElementoBarraPage name={'JURÍDICA'} onClick={Juridica} clase={'elemento-tete-page'}/>
        <ElementoBarraPage name={'DESARROLLO'} onClick={Desarrollo} clase={'elemento-tete-page'}/>
        <ElementoBarraPage name={'SIG'} onClick={Sig} clase={'elemento-tete-page'}/>
        <ElementoBarraPage name={'NOSOTROS'} onClick={Nosotros} clase={'elemento-tete-page'}/>
        <ElementoBarraPage name={'PQRS'} onClick={Pqr} clase={'elemento-tete-page'}/>
    </div>
  )
}

export default TetePage