import React from 'react'
import FormContact from '../components/formcontact'
import Whatsapp from '../components/whatsapp'
import { useNavigate } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { useEffect } from 'react'
import '../styles/inputs.css'
import { Helmet } from 'react-helmet';
import fondo from '../images/fondocontact.svg'


const Contactus = () => {


  useEffect(() => {
    AOS.init({duration: 1000});
    
  }, []);

  const returnIndex = useNavigate();

  const onReturn = () => {
    returnIndex('/');
  }

  return (
    <div className='org-contact'>

            <img src={fondo} alt="img fondo" className='fondo-contactus'/>

            <div >
            <Helmet>
                <title>Formulario de contacto</title>
                <meta name="description" content="Bienvenido al formulario de contacto de Geserpro S.A.S." />
                <meta name="keywords" content="contacto, contact us" />
                <meta name="author" content="Gestion y servicios productivos S.A.S." />
                <link rel="canonical" href="https://www.geserprosas.com/Contact" />
            </Helmet>

            
        <div class="container">
          <div class="row row-50 align-items-center justify-content-center justify-content-xl-between">
            <div class="col-lg-6 text-center wow fadeInUp">

            <div className='anuncio-principal'>CONTACTA CON NOSOTROS.</div>
            <div className='cuerpo-anuncio'> ¡Te damos la bienvenida a nuestra plataforma de contacto! Si deseas adquirir nuestros servicios o necesitas comunicarte con nosotros; completa el formulario a continuación. 
Nuestro equipo de profesionales te contactará en breve para ayudarte a dar el siguiente paso hacia tus objetivos y soluciones. Recuerda que estamos aquí para servirte y hacer realidad tus proyectos, esperamos poder brindarte la mejor asistencia posible
.
            </div>
            <a className='button button-secondary button-pipaluk' onClick={onReturn}>IR A LA PÁGINA PRINCIPAL</a>


            </div>
            <div class="col-lg-6 wow fadeInRight" data-wow-delay=".1s">
              <div class="box-width-lg-470">
                
                {/*<!-- Bootstrap tabs-->*/}
                <div class="tabs-custom tabs-horizontal tabs-line tabs-line-big tabs-line-style-2 text-center text-md-left" id="tabs-7">
                  {/*<!-- Tab panes-->*/}
                  <div class="tab-content">
                    <div class="tab-pane fade show active">
                    <FormContact clase={"formulario-contacto-contactus"}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      <Whatsapp/>

            </div>
            



    </div>
    
  )
}

export default Contactus
