import React from 'react'
import ReactPlayer from 'react-player'
import Pie from '../components/footer'
import Tete from '../components/tetePage'
import Whatsapp from '../components/whatsapp'
import { useNavigate } from 'react-router-dom'
import '../style.css'
import '../bootstrap.css'
import '../styles/fonds.css'
import '../styles/text.css'
import { Helmet } from 'react-helmet';


const Services = () => {

  const returnContact = useNavigate();

  const onContact = () => {
    returnContact('/Contact');
  }

  return (
    <div className='page'>

      <Helmet>
                <title>Geserprosas - Juridica</title>
                <meta name="description" content="Bienvenido a la pagina de juridica de Geserpro S.A.S. Aqui encontraras todos los servicios relacionados con asesoria en servicios juridicos para personas naturales y juridicas" />
                <meta name="keywords" content="servicios juridicos, empresas, demandas, procesos legales, divorcios, sucesiones, asesoria legal" />
                <meta name="author" content="Gestion y servicios productivos S.A.S." />
                <link rel="canonical" href="https://www.geserprosas.com/Juridica" />
      </Helmet>
      <Tete/>
    

{/*<!-- A que nos dedicamos-->*/}
<section class="section section-sm section-first bg-default text-md-left">
        <div class="container">
          <div class="row row-50 align-items-center justify-content-center justify-content-xl-between">
            <div class="col-lg-6 text-center wow fadeInUp"><ReactPlayer
                url={"https://youtu.be/8cevesatQZU"}
                width='100%' 
                height='400px'
                position= 'relative' 
                controls
                fallback={true}
                playing
                />
            </div>
            <div class="col-lg-6 wow fadeInRight" data-wow-delay=".1s">
              <div class="box-width-lg-470">
                <h3>GESTIÓN JURÍDICA.</h3>
                {/*<!-- Bootstrap tabs-->*/}
                <div class="tabs-custom tabs-horizontal tabs-line tabs-line-big tabs-line-style-2 text-center text-md-left" id="tabs-7">
                  {/*<!-- Tab panes-->*/}
                  <div class="tab-content">
                    <div class="tab-pane fade show active">
                      <p>¡Bienvenido a nuestro servicio de excelencia en gestión jurídica corporativa y de litigio! En nuestra empresa, nos enorgullece ofrecer soluciones integrales para satisfacer las necesidades legales y empresariales de personas jurídicas y naturales, tanto a nivel nacional como internacional.</p>
                      <div class="group-md group-middle"><a class="button button-secondary button-pipaluk" onClick={onContact}>Me interesa!</a><a class="button button-secondary button-pipaluk" href='#ServicesJur'>Saber mas</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<!--	Our Services-->*/}
      <div className='fond-services'>

      
      <section class="section section-sm" id='ServicesJur'>
        <div class="container">
          <h3 className='title-service'>Servicios Jurídicos</h3>
          <div class="row row-30">
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-equalization3"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Gestión Jurídica Corporativa</h5>
                    <p class="box-icon-classic-text">   - Evaluación exhaustiva de la estructura legal de tu empresa.<br/>
                                                        - Asesoramiento en la creación de políticas y procedimientos legales.<br/>
                                                        - Gestión proactiva para garantizar el cumplimiento normativo.<br/>
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-circular220"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Litigio</h5>
                    <p class="box-icon-classic-text"> - Representación legal en casos de litigio civil, mercantil o laboral.<br/>
                                                      - Estrategias efectivas para la resolución rápida y favorable de disputas legales.<br/>
                                                      - Seguimiento diligente de procedimientos judiciales.<br/>
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-favourites5"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Asesoría Jurídica Integral</h5>
                    <p class="box-icon-classic-text">   - Consultoría jurídica personalizada para abordar tus necesidades específicas.<br/>
                                                        - Respuestas ágiles a consultas legales y actualizaciones normativas.<br/>
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-headphones32"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Financiera</h5>
                    <p class="box-icon-classic-text"> - Revisión minuciosa de procesos administrativos y financieros.<br/>
                                                      - Identificación de áreas de mejora para una gestión más eficiente.<br/>
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-hot67"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Análisis de Gestión Administrativa  y Contable</h5>
                    <p class="box-icon-classic-text">   
                                                        - Asesoramiento en la implementación de mejores prácticas contables.<br/>
                                                        - Asesoría legal adaptada a empresas y particulares.<br/>
                                                        - Manejo integral de trámites legales para personas jurídicas y naturales.<br/>
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-wallet26"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Apoyo en Procesos Legales</h5>
                    <p class="box-icon-classic-text">- Contamos con expertos en leyes de inmigración que estarán a tu disposición para asegurar que cada paso que tomes esté respaldado por un conocimiento legal sólido.<br/>
                                                     - Representación y defensa de procesos litigiosos para las diferentes ramas del derecho
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      </div>
      <Pie/>
      <Whatsapp/>
        
  </div>
  )
}

export default Services