import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import Pie from '../components/footer'
import Tete from '../components/tetePage'
import Whatsapp from '../components/whatsapp'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { Helmet } from 'react-helmet';

const Nosotros = () => {
    useEffect(() => {
        setWidth(window.innerWidth);
        AOS.init({duration: 1000});
        
      }, []);
    
      //Capturer width
      const [width, setWidth] = useState();
      let Taille = width;

        const DivStyle = {
          color: 'blue',
          fontSize: '16px',
          padding: '10px',
        };

  return (
    
    
        <div>

      <Helmet>
                <title>Geserprosas - Contacto</title>
                <meta name="description" content="Bienvenido a la pagina nosotros de Geserpro S.A.S. Aqui encontraras todos los datos relacionados a nuestra historia, valores, mision y vision." />
                <meta name="keywords" content="Gestion y servicios productivos, sobre nosotros, mision, vision" />
                <meta name="author" content="Gestion y servicios productivos S.A.S." />
                <link rel="canonical" href="https://www.geserprosas.com/Nosotros" />
      </Helmet>
          <Tete/>
        
        <div class="content">
            <div className='linea-division'>
                <hr className='linea-horizontal-index'/>
                <h3 className='titulo-seccion'>RESEÑA HISTÓRICA</h3>
                <hr className='linea-horizontal-index'/>
            </div>
            
            <div className='caja-contenido' data-aos="fade-left">
            <div class="card">
              <p class="card-title"></p>
              <p class="small-desc">
    
              Gestión y Servicios Productivos S.A.S, empresa Colombiana fundada en año 2017, conformada por un equipo de profesionales altamente especializados en diversas áreas con el fin de  ofrecer servicios de asesoría y consultoría en campos como el jurídico, corporativo, tecnología de la información y sistemas integrados de gestión.<br/>
              Nos enorgullece destacar el alto compromiso, responsabilidad y confianza acreditada a nuestros clientes en la ejecución y suministro de nuestros servicios;  Nuestra compañía cuenta con una estructura corporativa sólida, que coincide y aporta activamente en la organización de los esquemas productivos de las empresas, y sus estructuras procesales jurídicas y de calidad, con el objetivo de optimizar sus esquemas de proyecto , crecimiento y rentabilidad de manera integral.<br/>
              La excelencia y calidad son sellos distintivos de nuestra compañía, respaldados por una extensa trayectoria en el sector. Proporcionamos acompañamiento jurídico y corporativo continuo a empresas de diversos sectores industriales,  comerciales, sociales , entre otros.

              
              </p>
            <div class="go-corner">
              <div class="go-arrow"></div>
            </div>
            </div>
            </div>
    
    
            <div className='linea-division'>
                <hr className='linea-horizontal-index' />
                <h3 className='titulo-seccion'>SOBRE NOSOTROS</h3>
                <hr className='linea-horizontal-index'/>
            </div>
    
    
            <div className='caja-contenido' data-aos="fade-right">
            <div class="card">
                <p class="card-title">MISIÓN</p>
                <p class="small-desc">
                GESERPRO SAS se compromete a ofrecer un servicio integral de consultoría y asesoría en áreas clave como jurídica, administrativa, contable y gestión de procesos integrados HSEQ, dirigido tanto a empresas como a personas naturales.<br/>
                Nos dedicamos a apoyar a una amplia gama de sectores, como industriales, comerciales, sociales, entre otros, trabajando incansablemente para optimizar sus procesos internos y externos.<br/>
                Nuestro compromiso radica en adaptar cada solución a las necesidades específicas de cada cliente, cumpliendo con los estándares exigidos en el gremio corporativo a nivel nacional e internacional. 
                <br/>
                En GESERPRO SAS, nos esforzamos por atender todos los requerimientos con profesionales altamente competentes, ofreciendo soluciones precisas que satisfagan las necesidades individuales de cada organización.
                La seguridad, confidencialidad y excelencia en el servicio son los principios fundamentales que guían el desarrollo de cada proceso en nuestra empresa. Estamos dedicados a fomentar el crecimiento y el éxito sostenible de nuestros clientes.

                </p>
                
            <div class="go-corner">
                <div class="go-arrow"></div>
            </div>
            </div>
            </div>
    
            <div className='caja-contenido' data-aos="fade-left">
            <div class="card">
              <p class="card-title">VISIÓN</p>
              <p class="small-desc">
              Para el año 2025, GESERPRO SAS se consolidará como un referente y líder indiscutible en la provisión del mejor servicio a través de nuestros departamentos de Gestión Integral, Jurídico y Tecnologías de Información, para personas jurídicas y naturales.<br/>
              Nos destacamos por brindar soluciones a medida para empresas de diversos sectores industriales, así como para clientes que requieren servicios especializados.<br/>
              A través de esta iniciativa, nos comprometemos a generar una imagen de confianza y seguridad en todos nuestros servicios. Queremos ser reconocidos por nuestra integridad y calidad, estableciendo una conexión sólida con nuestros clientes. GESERPRO SAS trabaja diariamente en proyectos de consolidación para ser la elección predilecta de empresas y personas que buscan excelencia y confiabilidad en sus servicios. Estamos decididos a forjar una reputación de innovación, integridad y liderazgo, elevando así el estándar de la consultoría integral.
              </p>
              
            <div class="go-corner">
            <div class="go-arrow"></div>
            </div>
            </div> 
            </div>
    
    
            <div className='linea-division'>
                <hr className='linea-horizontal-index'/>
                <h3 className='titulo-seccion'>POLÍTICA DE CALIDAD</h3>
                <hr className='linea-horizontal-index'/>
            </div>
    
    
            <div className='caja-contenido' data-aos="fade-right">
            <div class="card">
              <p class="small-desc">
              La Gerencia General de GESTIÓN Y SERVICIOS PRODUCTIVOS SAS reafirma su compromiso inquebrantable con la excelencia, determinando la disposición de recursos necesarios para asegurar el desarrollo óptimo de nuestra política de calidad. <br/>
              Nos especializamos en procesos de consultoría y asesoría en áreas clave como la jurídica, administrativa, contable, y gestión de procesos integrados HSEQ, todo en estricta conformidad con los requisitos legales, reglamentarios y las expectativas de nuestros clientes. <br/>
              Contamos con un talento humano altamente competente respaldado por recursos tecnológicos de vanguardia, asegurando la entrega de servicios de alta calidad y desarrollo profesional a nuestros clientes.<br/>
              Nuestro compromiso se extiende a la mejora continua de nuestro Sistema de Gestión de Calidad, promoviendo la comunicación asertiva desde la alta dirección hasta las partes interesadas, buscando alinear sus necesidades y expectativas con los objetivos de la empresa y su impacto en los entornos social, laboral y ambiental. Mantenemos estándares elevados de confiabilidad, aspirando a ser un referente en nuestro sector.<br/>
              En GESTIÓN Y SERVICIOS PRODUCTIVOS SAS, perseguimos la excelencia en cada paso y nos esforzamos por ser líderes en la satisfacción de las necesidades de nuestros clientes y en la contribución positiva a nuestro entorno.

              </p>
              
            <div class="go-corner">
            <div class="go-arrow"></div>
            </div>
            </div> 
            </div>
            <div style={DivStyle}></div>
            <Whatsapp/>
            
            </div>
            <Pie/>
            </div>
  )
}

export default Nosotros