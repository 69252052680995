import React from 'react'
import ReactPlayer from 'react-player'
import Pie from '../components/footer'
import Tete from '../components/tetePage'
import Whatsapp from '../components/whatsapp'
import { useNavigate } from 'react-router-dom'
import '../styles/sections.css'
import { Helmet } from 'react-helmet';

const Servicequalit = () => {

  const returnContact = useNavigate();

  const onContact = () => {
    returnContact('/Contact');
  }

  return (
    <div className='page'>
      

      <Helmet>
                <title>Geserprosas - Sistema Integrado de Gestion</title>
                <meta name="description" content="Bienvenido a la pagina de SIG de Geserpro S.A.S. Aqui encontraras todos los servicios relacionados con sistemas integrados de gestion" />
                <meta name="keywords" content="sistema integrado de gestion, asesoria, calidad, ISO, auditoria, empresas, salud y seguridad en el trabajo" />
                <meta name="author" content="Gestion y servicios productivos S.A.S." />
                <link rel="canonical" href="https://www.geserprosas.com/SIG" />
      </Helmet>

        <Tete/>
      
      
        {/*<!-- A que nos dedicamos-->*/}
        <section class="section section-sm section-first bg-default text-md-left">
        <div class="container">
          <div class="row row-50 align-items-center justify-content-center justify-content-xl-between">
            <div class="col-lg-6 text-center wow fadeInUp"><ReactPlayer
                url={"https://youtu.be/pNUDzYkdlgg"}
                width='100%'
                position= 'relative' 
                controls
                fallback={true}
                height='400px'
                playing
            />
            </div>
            <div class="col-lg-6 wow fadeInRight" data-wow-delay=".1s">
              <div class="box-width-lg-470">
                <h3>SISTEMA INTEGRADO DE GESTIÓN</h3>
                {/*<!-- Bootstrap tabs-->*/}
                <div class="tabs-custom tabs-horizontal tabs-line tabs-line-big tabs-line-style-2 text-center text-md-left" id="tabs-7">
                  {/*<!-- Tab panes-->*/}
                  <div class="tab-content">
                    <div class="tab-pane fade show active">
                      <p>Consulta, asesoría, análisis, implementación, seguimiento y control de la gestión integral de la calidad, inocuidad, salud, y seguridad en servicios corporativos de personas naturales y jurídicas, nacionales e internacionales.</p>
                      <div class="group-md group-middle"><a class="button button-secondary button-pipaluk" onClick={onContact}>Me interesa!</a><a class="button button-secondary button-pipaluk" href='#ServicesQua'>Saber mas</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
        {/*<!--	Our Services-->*/}
        <div className='fond-services'>
      <section class="section section-sm" id='ServicesQua'>
        <div class="container">
          <h3 className='title-service'>Servicios SIG</h3>
          <div class="row row-30">
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-equalization3"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Sistema Integrado de Gestión</h5>
                    <p class="box-icon-classic-text">  - Desarrollo y personalización de sistemas que unifican la gestión de calidad, inocuidad, salud y seguridad.<br/>
                                                        - Implementación de metodologías eficientes para la integración de procesos.

                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-circular220"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Consulta y Asesoría</h5>
                    <p class="box-icon-classic-text"> - Asesoramiento especializado para definir objetivos y requisitos normativos.<br/>
                                                      - Consultas continuas para garantizar la adaptación a cambios normativos y mejores prácticas.

                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-favourites5"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Análisis Integral</h5>
                    <p class="box-icon-classic-text">     - Evaluación detallada de la situación actual de tu gestión de calidad, salud y seguridad.<br/>
                                                          - Identificación de áreas de mejora y oportunidades para optimizar procesos.

                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-headphones32"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Implementación Efectiva</h5>
                    <p class="box-icon-classic-text"> - Despliegue de estrategias y herramientas para implementar de manera efectiva el Sistema Integrado de Gestión.<br/>
                                                      - Capacitación del personal para asegurar una transición sin contratiempos.

                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-hot67"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Seguimiento y Control</h5>
                    <p class="box-icon-classic-text">   
                                                    - Monitoreo constante para evaluar el desempeño del sistema.<br/>
                                                    - Implementación de controles para garantizar el cumplimiento de estándares y regulaciones.

                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-wallet26"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Auditorías internas y externas</h5>
                    <p class="box-icon-classic-text">Realización de auditorías para evaluar el desempeño del sistema integrado de gestión y asegurar el cumplimiento de los requisitos establecidos por las normativas y estándares aplicables.</p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
        </div>
      
      
          <Pie/>
      
      <Whatsapp/>    
  </div>
  )
}

export default Servicequalit