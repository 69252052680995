import React from 'react'
import ReactPlayer from 'react-player'
import Pie from '../components/footer'
import Tete from '../components/tetePage'
import Whatsapp from '../components/whatsapp'
import Boton from '../components/button'
import { useNavigate } from 'react-router-dom'
import gridAdvantage from '../components/gridAdvantages'
import GridAdvantages from '../components/gridAdvantages'
import { Helmet } from 'react-helmet';

const Serviceti = () => {

  const returnContact = useNavigate();

  //Style grid advantage
  const styleGridAdv = {
    width: "40%",
    margin: "0% 5% 5% 30%"
  }

  const onContact = () => {
    returnContact('/Contact');
  }

  return (
    <div className='page'>


      <Helmet>
                <title>Geserprosas - Desarrollo</title>
                <meta name="description" content="Bienvenido a la pagina de desarrollo de Geserpro S.A.S. Aqui encontraras todos los servicios relacionados con el desarrollo web, aplicaciones mobiles de escritorio a la medida para empresas y emprendimientos." />
                <meta name="keywords" content="desarrollo web,desarrollo mobil, desarrollo aplicaciones, aplicaciones a medida, pagina web, programacion, programacion web, programas" />
                <meta name="author" content="Gestion y servicios productivos S.A.S." />
                <link rel="canonical" href="https://www.geserprosas.com/Desarrollo" />
      </Helmet>
      
      <Tete/>



{/*<!-- A que nos dedicamos-->*/}
<section class="section section-sm section-first bg-default text-md-left">
        <div class="container">
          <div class="row row-50 align-items-center justify-content-center justify-content-xl-between">
            <div class="col-lg-6 text-center wow fadeInUp"><ReactPlayer
                url={"https://youtu.be/cj-US14zi2c"}
                width='100%'
                height='400px'
                position= 'relative' 
                controls
                fallback={true}
                playing
            />
            </div>
            <div class="col-lg-6 wow fadeInRight" data-wow-delay=".1s">
              <div class="box-width-lg-470">
                <h3>DESARROLLO A MEDIDA</h3>
                {/*<!-- Bootstrap tabs-->*/}
                <div class="tabs-custom tabs-horizontal tabs-line tabs-line-big tabs-line-style-2 text-center text-md-left" id="tabs-7">
                  {/*<!-- Tab panes-->*/}
                  <div class="tab-content">
                    <div class="tab-pane fade show active">
                      <p>¡Saludos! En nuestra empresa, estamos emocionados de ofrecerte servicios de vanguardia en implementación de herramientas tecnológicas corporativas y desarrollo de aplicaciones que transformarán tu visión empresarial en una realidad innovadora. Aquí te presentamos los aspectos destacados de nuestros servicios.</p>
                      <div class="group-md group-middle"><a class="button button-secondary button-pipaluk" onClick={onContact}>Me interesa!</a><a class="button button-secondary button-pipaluk" href='#ServicesTI'>Saber mas</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/*<!--	Our Services-->*/}
      <div className='fond-services'>
      <section class="section section-sm" id='ServicesTI'>
        <div class="container">
          <h3 className='title-service'>Servicios Desarrollo</h3>
          <div class="row row-30">
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-equalization3"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Implementación de Herramientas Tecnológicas Corporativas</h5>
                    <p class="box-icon-classic-text">   - Evaluación exhaustiva de las necesidades específicas de tu empresa.<br/>
                                                        - Implementación de soluciones tecnológicas personalizadas para mejorar la eficiencia y la productividad.<br/>
                                                        - Integración de sistemas para una operación empresarial fluida y sin inconvenientes.<br/>

                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-circular220"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Desarrollo de Aplicaciones Web</h5>
                    <p class="box-icon-classic-text"> - Creación de aplicaciones web modernas y atractivas que reflejen la identidad de tu marca.<br/>
                                                      - Experiencia de usuario optimizada para una navegación intuitiva.<br/>
                                                      - Desarrollo ágil y escalable para adaptarse al crecimiento de tu empresa.<br/>

                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-favourites5"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Desarrollo de Aplicaciones Móviles</h5>
                    <p class="box-icon-classic-text"> - Creación de aplicaciones móviles personalizadas para iOS y Android.<br/>
                                                      - Diseño centrado en el usuario para una experiencia móvil excepcional.<br/>

                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-headphones32"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Desarrollo de Software Empresarial</h5>
                    <p class="box-icon-classic-text">Creación de soluciones de software para la gestión de empresas, como sistemas de gestión de recursos empresariales (ERP), sistemas de gestión de relaciones con el cliente (CRM), etc.
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-hot67"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Desarrollo de Aplicaciones de Escritorio Multiplataforma</h5>
                    <p class="box-icon-classic-text">   
                                                      - Desarrollo de aplicaciones de escritorio que funcionan en diferentes sistemas operativos.<br/>
                                                      - Interfaz de usuario intuitiva y diseño adaptativo.<br/>
                                                      - Mantenimiento y actualización continuos para garantizar un rendimiento óptimo<br/>

                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-wallet26"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Mantenimiento y Soporte de Software</h5>
                    <p class="box-icon-classic-text">Proporcionamos soporte y servicios de mantenimiento continuo, actualizaciones, parches de seguridad, optimizacion y soporte técnico para el software desarrollado.</p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      </div>
        <Whatsapp/>
        <Pie/>
  </div>
  )
}

export default Serviceti