import React from 'react'
import Tete from '../components/tetePage'
import '../bootstrap.css'
import '../style.css'
import Quebec from '../images/imagesMigration/Quebec Ciity2.webp'
import Lake from '../images/imagesMigration/lake.webp'
import Bandera from '../images/imagesMigration/Bandera.webp'
import Bosque from '../images/imagesMigration/Bosque.webp'
import Chateau from '../images/imagesMigration/Quebec.webp'
import Montreal from '../images/imagesMigration/Montreal.webp'
import Rocosas from '../images/imagesMigration/Rocosas.webp'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import '../styles/slider.css'
import Footer from '../components/footer'
import Whatsapp from '../components/whatsapp'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet';



const Migration = () => {


  const returnContact = useNavigate();

  const onContact = () => {
    returnContact('/Contact');
  }


    //Variable slide image
        const settings = {
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
        };


  return (
    <div class="page">

      <Helmet>
                <title>Geserprosas - Migracion</title>
                <meta name="description" content="Bienvenido a la pagina de migracion de Geserpro S.A.S. Aqui encontraras todos los servicios relacionados con la asesoria y acompanamiento para obtencion de visa Canadiense/Usa. Asesoria en procesos migratorios" />
                <meta name="keywords" content="Canada, Estados Unidos, Visa, migracion, inmigracion" />
                <meta name="author" content="Gestion y servicios productivos S.A.S." />
                <link rel="canonical" href="https://www.geserprosas.com/Migracion" />
      </Helmet>
      <Tete/>
    
    <Slider {...settings}>
      <div class="swiper-slide context-dark">
        <div className='cont-img'>
        <img src={Bandera} alt="Imagen 1" />
        </div> 
      </div>
      <div class="swiper-slide context-dark">
        <div className='cont-img'>
          <img src={Lake} alt="Imagen 2" />
        </div>
        
        
      </div>
      <div class="swiper-slide context-dark">
        <div className='cont-img'>
        <img src={Bosque} alt="Imagen 3" />
        </div>
        
        
      </div>
      <div class="swiper-slide context-dark">
        <div className='cont-img'>
        <img src={Chateau} alt="Imagen 3" />
        </div>
        
      </div >
      <div class="swiper-slide context-dark">
        <div className='cont-img'>
        <img src={Montreal} alt="Imagen 3" />
        </div>
        
      </div >
      <div class="swiper-slide context-dark">
        <div className='cont-img'>
        <img src={Rocosas} alt="Imagen 3" />
        </div>
        
      </div >

   
    
      {/* Agrega más imágenes según sea necesario */}
    </Slider>
      {/*<!-- Swiper-->*/}
        <div class="swiper-wrapper text-left">
          <div class="swiper-slide context-dark">
            <div class="swiper-slide-caption section-md">
              <div class="container">
                <div class="row">
                  <div class="col-md-10">
                    <h6 class="text-uppercase" data-caption-animate="fadeInRight" data-caption-delay="0">Contamos con expertos en los diferentes procesos de inmigración que estarán a tu disposición para asegurar que cada paso que tomes esté respaldado por un conocimiento legal sólido</h6>
                    <h2 class="oh font-weight-light" data-caption-animate="slideInUp" data-caption-delay="100"><span>Explora</span><span class="font-weight-bold"> Canada</span></h2><a class="button button-default-outline button-ujarak" data-caption-animate="fadeInLeft" data-caption-delay="0" onClick={onContact}>Contactanos</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
      {/*<!-- Discover New Horizons-->*/}
      <section class="section section-sm section-first bg-default text-md-left">
        <div class="container">
          <div class="row row-50 align-items-center justify-content-center justify-content-xl-between">
            <div class="col-lg-6 text-center wow fadeInUp"><img src={Quebec} alt="" width="556" height="382"/>
            </div>
            <div class="col-lg-6 wow fadeInRight" data-wow-delay=".1s">
              <div class="box-width-lg-470">
                <h3>Descubre nuevos horizontes</h3>
                {/*<!-- Bootstrap tabs-->*/}
                <div class="tabs-custom tabs-horizontal tabs-line tabs-line-big tabs-line-style-2 text-center text-md-left" id="tabs-7">
                  {/*<!-- Tab panes-->*/}
                  <div class="tab-content">
                    <div class="tab-pane fade show active">
                      <p>En Gestión y servicios productivos, estamos comprometidos a hacer que tu transición a Canadá sea lo más eficaz posible. ¡Contáctanos hoy y comencemos a construir tu futuro en Canadá!.</p>
                      <div class="group-md group-middle"><a class="button button-secondary button-pipaluk" href="#Services">Ir a servicios</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<!--	Our Services-->*/}
      <section class="section section-sm" id='Services'>
        <div class="container">
          <h3>Nuestros Servicios</h3>
          <div class="row row-30">
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-equalization3"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Asesoramiento integral</h5>
                    <p class="box-icon-classic-text">Nuestro experimentado equipo de asesores te guiará a lo largo de todo el proceso, desde la evaluación de elegibilidad hasta la obtención de la residencia permanente.</p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-circular220"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Documentación</h5>
                    <p class="box-icon-classic-text"> Nos encargamos de la preparación meticulosa de todos los documentos necesarios para tu aplicación, garantizando que cumplas con todos los requisitos de inmigración canadienses.</p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-favourites5"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Servicios de Integración</h5>
                    <p class="box-icon-classic-text">No terminamos nuestro trabajo cuando llegas a Canadá. Ofrecemos servicios de integración para ayudarte a adaptarte a tu nueva vida, incluyendo orientación laboral y asistencia en la búsqueda de vivienda.</p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-headphones32"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Evaluación de Elegibilidad</h5>
                    <p class="box-icon-classic-text">Realizaremos una evaluación detallada de tu elegibilidad para el programa de inmigración canadiense.</p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-hot67"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Plan Personalizado</h5>
                    <p class="box-icon-classic-text"> Desarrollaremos un plan personalizado para tu proceso de inmigración, brindándote transparencia sobre cada paso a seguir.

                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-sm-6 col-lg-4">
              <article class="box-icon-classic">
                <div class="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                  <div class="unit-left">
                    <div class="box-icon-classic-icon fl-bigmug-line-wallet26"></div>
                  </div>
                  <div class="unit-body">
                    <h5 class="box-icon-classic-title">Apoyo en Procesos Legales</h5>
                    <p class="box-icon-classic-text">Contamos con expertos en leyes de inmigración que estarán a tu disposición para asegurar que cada paso que tomes esté respaldado por un conocimiento legal sólido.</p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      
      {/*<!-- Section Subscribe-->*/}
      <section class="section bg-default text-center offset-top-50">
        <div class="parallax-container" >
          <div class="parallax-content section-xl section-inset-custom-1 context-dark bg-overlay-2-21">
            <div class="container">
              <h2 class="heading-2 oh font-weight-normal wow slideInDown"><span class="d-block font-weight-semi-bold">¡Contáctanos hoy y comencemos</span><span class="d-block font-weight-light">a construir tu futuro en Canadá!</span></h2>
              <button class="button button-secondary button-pipaluk" onClick={onContact}>CONTACTANOS</button>
            </div>
          </div>
        </div>
      </section>
      
    <Footer/>
    
    <Whatsapp/>
    </div>
  )
}

export default Migration
